import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../library/constant";

export default function DashboardPage() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const token = localStorage.getItem("token");

    axios.get(`${baseUrl}dashboard`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      setData(response.data);
    })
    .catch(error => {
      console.error("Error fetching dashboard data:", error);
    });
  };

  const handlePostItem = (id) => {
    const token = localStorage.getItem("token");
  
    axios.post(`${baseUrl}post-record`, {
      _id: id
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(() => {
      fetchData();
    })
    .catch(error => {
      console.error("Error posting record:", error);
    });
  };

  const handleDelete = (id) => {
    const token = localStorage.getItem("token");

    axios.delete(`${baseUrl}delete-record`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        _id: id
      }
    })
    .then(() => {
      fetchData();
    })
    .catch(error => {
      console.error("Error deleting record:", error);
    });
  };

  return (
    <>
      <h1>RSS News</h1>
      <div style={{ marginTop: '50px' }}>
        {data.map((item, index) => (
          <div style={{ padding: '20px', borderBlockEnd: '1px solid black', borderBlockStart: '1px solid black' }} key={index}>
            <h4 style={{ width: '500px' }}>{item.title}</h4>
            <img src={item.imageLink} alt={item.title} style={{ width: '500px' }} />
            <h4>Original Description</h4>
            <p style={{ width: '500px' }}>{item.description}</p>
            <h4>GPT Description</h4>
            <p style={{ width: '500px' }}>{item.gptDescription}</p>
            <button style={{ marginRight: '30px', color: 'black', borderRadius: '8px', border: '1px solid green', padding: '10px 20px', cursor: 'pointer', fontSize: '16px', textAlign: 'center', textDecoration: 'none', display: 'inline-block' }} onClick={() => handlePostItem(item._id)}>Post</button>
            <button style={{ backgroundColor: 'red', color: 'white', borderRadius: '8px', border: 'none', padding: '10px 20px', cursor: 'pointer', fontSize: '16px', textAlign: 'center', textDecoration: 'none', display: 'inline-block' }} onClick={() => handleDelete(item._id)}>Delete</button>
            <p style={{ marginTop: '5px' }}><b>Pull date:</b> {new Date(item.createdAt).toDateString()}</p>
          </div>
        ))}
      </div>
    </>
  );
}
