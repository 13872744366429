import React, { useEffect, useState } from "react";
import { Password } from 'primereact/password';
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import "../assets/css/login.css";
import { useParams, useHistory } from "react-router-dom"; // Import useParams to get the token parameter
import axios from 'axios'; // Import Axios
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { baseUrl } from "../library/constant";

export default function ChangepasswordPage() {
  const [token, setToken] = useState(""); // State to store the token parameter
  const ForgotSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    passwordConfirm: Yup.string()
      .required("Please fill in this field")
      .oneOf([Yup.ref("password"), null], "Passwords don't match"),
  });
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema: ForgotSchema,
    onSubmit: async (data) => {
      try {
        await axios.patch(`${baseUrl}reset-password/${token}`, {
          password: data.password,
          passwordConfirm: data.passwordConfirm
        });

        history.push('/login');

      } catch (error) {
        console.error('Error occurred:', error);
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit } = formik;

  // Extract the token parameter from the URL
  const { token: urlToken } = useParams();

  useEffect(() => {
    if (urlToken) {
      setToken(urlToken);
    }
  }, [urlToken]);

  return (
    <div className="form-box">
      <div className="fullHeight p-ai-center p-d-flex p-jc-center">
        <div className="shadow card m-3 px-3 py-4 px-sm-4 py-sm-5">
          <h4 className="text-center">Reset Password</h4>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit} className="p-fluid">
              <div className="p-field">
                <span className="p-float-label">
                  <Password
                    id="password"
                    name="password"
                    toggleMask
                    feedback={false}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": Boolean(touched.password && errors.password),
                    })}
                  />
                  <label
                    htmlFor="password"
                    className={classNames({
                      "p-error": Boolean(touched.password && errors.password),
                    })}
                  >
                    Password*
                  </label>
                </span>
                {Boolean(touched.password && errors.password) && (
                  <small className="p-error">{formik.errors.password}</small>
                )}
              </div>

              <div className="p-field">
                <span className="p-float-label">
                  <Password
                    id="passwordConfirm"
                    name="passwordConfirm"
                    toggleMask
                    feedback={false}
                    value={formik.values.passwordConfirm}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": Boolean(touched.passwordConfirm && errors.passwordConfirm),
                    })}
                  />
                  <label
                    htmlFor="passwordConfirm"
                    className={classNames({
                      "p-error": Boolean(touched.passwordConfirm && errors.passwordConfirm),
                    })}
                  >
                    Confirm Password*
                  </label>
                </span>
                {Boolean(touched.passwordConfirm && errors.passwordConfirm) && (
                  <small className="p-error">{formik.errors.passwordConfirm}</small>
                )}
              </div>

              <div className="submitBtnBox">
                <Button
                  type="submit"
                  label="Submit"
                  iconPos="right"
                  loading={isSubmitting}
                  className="mt-4 submitBtn"
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
}
